import { Box, CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ModalProvider from 'components/CustomModal/ModalProvider';
import { Toaster } from 'components/Toast';
import Wrapper from 'components/layout/Wrapper/Wrapper';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { themeOptions as alphaTheme } from '../constants/alphaTheme';
import { themeOptions as lightTheme } from '../constants/lightTheme';

const globalStyles = (
  <GlobalStyles
    styles={() => ({
      body: {
        backgroundColor: '#000',
      },
    })}
  />
);

const App: FC = () => {
  const themeMode = useSelector((state) => state.user.theme);
  const theme = useMemo(() => {
    if (themeMode === 'alpha') {
      return createTheme(alphaTheme);
    }
    return createTheme(lightTheme);
  }, [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        {/* make body black for alpha */}
        {themeMode === 'alpha' && globalStyles}
        <ModalProvider>
          <Box>
            <Wrapper />
          </Box>
        </ModalProvider>
        <Toaster position="bottom-left" />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
