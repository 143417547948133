import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      'sans-serif',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#09b517',
      dark: '#008000',
    },
    secondary: {
      main: '#fb827f',
    },
    text: {
      disabled: '#969696',
    },
    success: {
      main: '#09b517',
    },
    warning: {
      main: '#f0ad4e',
    },
    error: {
      main: '#db4240',
      dark: '#c62828',
      light: '#ff0000',
    },
    info: {
      main: '#5a95f0',
    },
    custom: {
      main: '#fff',
    },
  },
};
