import { createSlice, Slice } from '@reduxjs/toolkit';
import { setCharAt } from 'utils/helpers';
import { spinData } from './constants/spinData';

type SpinState = {
  selections: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roulette: any;
  cleared: boolean;
  refreshed: boolean;
  repeat: number;
};

const initialState: SpinState = {
  selections: [],
  roulette: spinData,
  cleared: true,
  refreshed: true,
  repeat: 1,
};

const spinSlice: Slice<SpinState> = createSlice({
  name: 'keno',
  initialState,
  reducers: {
    toggleRouletteNumsHover: (state, { payload }) => {
      payload.nums.forEach((num) => {
        state.roulette[num].isHovered = payload.isHovered;
      });
    },
    toggleRouletteNeghborsHover: (state, { payload }) => {
      payload.nums.forEach((num) => {
        state.roulette.neighbors[num].isHovered = payload.isHovered;
      });
    },
    toggleRouletteSelected: (state, { payload }) => {
      state.roulette[payload.id].isSelected = !state.roulette[payload.id].isSelected;
    },
    toggleRouletteNeighborSelected: (state, { payload }) => {
      state.roulette.neighbors[payload.id].isSelected =
        !state.roulette.neighbors[payload.id].isSelected;
    },
    toggleRouletteBtnSelected: (state, { payload }) => {
      const { market } = state.roulette[payload.numId].buttons[payload.btnId];
      if (market === '3Line' || market === 'Six') {
        const num = parseInt(payload.numId, 10);
        let numId2 = payload.numId;
        let btnId2 = payload.btnId;
        let btnPos = btnId2.split('SW-')[1];

        const col = num % 3 === 0 ? 'top' : 'bottom';
        if (col === 'top') {
          numId2 = (num - 2).toString();
          btnPos = setCharAt(btnPos, 0, 'B');
          btnId2 = `SW-${btnPos}`;
        } else {
          numId2 = (num + 2).toString();
          btnPos = setCharAt(btnPos, 0, 'T');
          btnId2 = `SW-${btnPos}`;
        }
        state.roulette[numId2].buttons[btnId2].isSelected =
          !state.roulette[numId2].buttons[btnId2].isSelected;
      }
      state.roulette[payload.numId].buttons[payload.btnId].isSelected =
        !state.roulette[payload.numId].buttons[payload.btnId].isSelected;
    },
    initSpinState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

// eslint-disable-next-line operator-linebreak
export const {
  toggleRouletteNumsHover,
  toggleRouletteNeghborsHover,
  toggleRouletteSelected,
  toggleRouletteNeighborSelected,
  toggleRouletteBtnSelected,
  initSpinState,
} = spinSlice.actions;
export default spinSlice.reducer;
