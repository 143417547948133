import { createSlice, Slice } from '@reduxjs/toolkit';
import { SeverityType } from 'components/Toast';

export type BetItemType = {
  id: string;
  timestamp: string;
  gameId: number;
  gameNumber: number;
  gameType: string;
  resultTimestamp: Date;
  market: 'Win' | 'Heads and Tails';
  odd: number;
  picks: number[] | string[];
  amount: number;
  dirty: boolean;
  error: boolean;
  closeFn: Function | undefined;
};

type BetSlipType = {
  betItems: BetItemType[];
  expiredItems: BetItemType[];
  // active tracks the current selected bet item id
  active: string;
  toast: null | { severity: SeverityType; message: string };
  globalStake: number;
};

const initialState: BetSlipType = {
  betItems: [],
  expiredItems: [],
  active: '',
  toast: null,
  globalStake: 0,
};

const betSlipSlice: Slice<BetSlipType> = createSlice({
  name: 'betSlip',
  initialState,
  reducers: {
    addBet: (state, { payload }: { payload: BetItemType }) => {
      state.betItems.push({ ...payload, dirty: false, error: false });
      state.toast = null;
    },
    removeBet: (state, { payload }: { payload: BetItemType }) => {
      const index = state.betItems.findIndex((betItem) => betItem.id === payload.id);
      if (index !== -1) {
        state.betItems.splice(index, 1);
      }
    },
    updateBetAmount: (state, { payload }) => {
      const index = state.betItems.findIndex((betItem) => betItem.id === payload.id);
      if (index !== -1) {
        state.betItems[index].amount = payload.amount;
      }
    },

    updateGlobalStake: (state, { payload }) => {
      state.betItems.forEach((betItem) => {
        betItem.amount = payload.amount;
      });
    },
    clearBet: (state) => {
      state.betItems = [];
      state.globalStake = 0;
    },
    setActive: (state, { payload }) => {
      state.active = payload.id;
    },
    setDirty: (state, { payload }) => {
      const index = state.betItems.findIndex((betItem) => betItem.id === payload.id);
      if (index !== -1) {
        state.betItems[index].dirty = payload.status;
      }
    },
    setError: (state, { payload }) => {
      const index = state.betItems.findIndex((betItem) => betItem.id === payload.id);
      if (index !== -1) {
        state.betItems[index].error = payload.error;
      }
    },
    moveToExpired: (state, { payload }) => {
      state.expiredItems = state.betItems.filter((item) => item.gameId === payload.id);
      state.betItems = state.betItems.filter((item) => item.gameId !== payload.id);
    },
    clearExpired: (state) => {
      state.expiredItems = [];
    },
    setToast: (state, { payload }) => {
      state.toast = payload;
    },
    setGlobalStake: (state, { payload }) => {
      state.globalStake = payload;
    },
  },
});

// eslint-disable-next-line operator-linebreak
export const {
  addBet,
  removeBet,
  updateBetAmount,
  updateGlobalStake,
  clearBet,
  setActive,
  setDirty,
  moveToExpired,
  clearExpired,
  setToast,
  setError,
  setGlobalStake,
} = betSlipSlice.actions;
export default betSlipSlice.reducer;
