/* eslint-disable quote-props */
export const spinData = {
  '0': {
    isSelected: false,
    isHovered: false,
    bgColor: '#008000',
    market: 'Win',
    buttons: {
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['0', '3'],
        market: 'Split',
      },
      'SW-0M': {
        class: 'SW-0M',
        isSelected: false,
        isActive: false,
        numberRange: ['0', '2'],
        market: 'Split',
      },
      'SW-0BR': {
        class: 'SW-0BR',
        isSelected: false,
        isActive: false,
        numberRange: ['0', '1'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['0', '1', '2', '3'],
        market: '4',
      },
    },
  },
  '3': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '3'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '3', '4', '5', '6'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['3', '6'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['2', '3', '5', '6'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['2', '3'],
        market: 'Split',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['0', '3'],
        market: 'Split',
      },
    },
  },
  '6': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '6'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '6', '7', '8', '9'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['6', '9'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['5', '6', '8', '9'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['5', '6'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['2', '3', '5', '6'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['3', '6'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '3', '4', '5', '6'],
        market: 'Six',
      },
    },
  },
  '9': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '9'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '9', '10', '11', '12'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['9', '12'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['8', '9', '11', '12'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['8', '9'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['5', '6', '8', '9'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['6', '9'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '6', '7', '8', '9'],
        market: 'Six',
      },
    },
  },
  '12': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '12'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '12', '13', '14', '15'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['12', '15'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['11', '12', '14', '15'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['11', '12'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['8', '9', '11', '12'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['9', '12'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '9', '10', '11', '12'],
        market: 'Six',
      },
    },
  },
  '15': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '15'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '15', '16', '17', '18'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['15', '18'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['14', '15', '17', '18'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['14', '15'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['11', '12', '14', '15'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['12', '15'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '12', '13', '14', '15'],
        market: 'Six',
      },
    },
  },
  '18': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '18'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '18', '19', '20', '21'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['18', '21'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['17', '18', '20', '21'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['17', '18'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['14', '15', '17', '18'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['15', '18'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '15', '16', '17', '18'],
        market: 'Six',
      },
    },
  },
  '21': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '21'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '21', '22', '23', '24'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['21', '24'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['20', '21', '23', '24'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['20', '21'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['17', '18', '20', '21'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['18', '21'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '18', '19', '20', '21'],
        market: 'Six',
      },
    },
  },
  '24': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '24'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '24', '25', '26', '27'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['24', '27'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['23', '24', '26', '27'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['23', '24'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['20', '21', '23', '24'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['21', '24'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '21', '22', '23', '24'],
        market: 'Six',
      },
    },
  },
  '27': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '27'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '27', '28', '29', '30'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['27', '30'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['26', '27', '29', '30'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['26', '27'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['23', '24', '26', '27'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['24', '27'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '24', '25', '26', '27'],
        market: 'Six',
      },
    },
  },
  '30': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '30'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '30', '31', '32', '33'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['30', '33'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['29', '30', '32', '33'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['29', '30'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['26', '27', '29', '30'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['27', '30'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '27', '28', '29', '30'],
        market: 'Six',
      },
    },
  },
  '33': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '33'],
        market: '3Line',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '33', '34', '35', '36'],
        market: 'Six',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['33', '36'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['32', '33', '35', '36'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['32', '33'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['29', '30', '32', '33'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['30', '33'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '30', '31', '32', '33'],
        market: 'Six',
      },
    },
  },
  '36': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['34', '35', '36'],
        market: '3Line',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['35', '36'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['32', '33', '35', '36'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['33', '36'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '33', '34', '35', '36'],
        market: 'Six',
      },
    },
  },
  '2': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['2', '3'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['2', '3', '5', '6'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['2', '5'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '4', '5'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2'],
        market: 'Split',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['0', '2'],
        market: 'Split',
      },
    },
  },
  '5': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['5', '6'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['5', '6', '8', '9'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['5', '8'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '7', '8'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '4', '5'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['2', '5'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['2', '3', '5', '6'],
        market: 'Corner',
      },
    },
  },
  '8': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['8', '9'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['8', '9', '11', '12'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['8', '11'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '10', '11'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '7', '8'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['5', '8'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['5', '6', '8', '9'],
        market: 'Corner',
      },
    },
  },
  '11': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['11', '12'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['11', '12', '14', '15'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['11', '14'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '13', '14'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '10', '11'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['8', '11'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['8', '9', '11', '12'],
        market: 'Corner',
      },
    },
  },
  '14': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['14', '15'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['14', '15', '17', '18'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['14', '17'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '16', '17'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '13', '14'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['11', '14'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['11', '12', '14', '15'],
        market: 'Corner',
      },
    },
  },
  '17': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['17', '18'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['17', '18', '20', '21'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['17', '20'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '19', '20'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '16', '17'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['14', '17'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['14', '15', '17', '18'],
        market: 'Corner',
      },
    },
  },
  '20': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['20', '21'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['20', '21', '23', '24'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['20', '23'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '22', '23'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '19', '20'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['17', '20'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['17', '18', '20', '21'],
        market: 'Corner',
      },
    },
  },
  '23': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['23', '24'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['23', '24', '26', '27'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['23', '26'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '25', '26'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '22', '23'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['20', '23'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['20', '21', '23', '24'],
        market: 'Corner',
      },
    },
  },
  '26': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['26', '27'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['26', '27', '29', '30'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['26', '29'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '28', '29'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '25', '26'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['23', '26'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['23', '24', '26', '27'],
        market: 'Corner',
      },
    },
  },
  '29': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['29', '30'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['29', '30', '32', '33'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['29', '32'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '31', '32'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '28', '29'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['26', '29'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['26', '27', '29', '30'],
        market: 'Corner',
      },
    },
  },
  '32': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['32', '33'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['32', '33', '35', '36'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['32', '35'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '34', '35'],
        market: 'Corner',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '31', '32'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['29', '32'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['29', '30', '32', '33'],
        market: 'Corner',
      },
    },
  },
  '35': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['35', '36'],
        market: 'Split',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['34', '35'],
        market: 'Split',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '34', '35'],
        market: 'Corner',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['32', '35'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['32', '33', '35', '36'],
        market: 'Corner',
      },
    },
  },
  '1': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '4', '5'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '4'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '3', '4', '5', '6'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '3'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['0', '1', '2', '3'],
        market: '4',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['0', '1'],
        market: 'Split',
      },
    },
  },
  '4': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '7', '8'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '7'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '6', '7', '8', '9'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '6'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '3', '4', '5', '6'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '4'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['1', '2', '4', '5'],
        market: 'Corner',
      },
    },
  },
  '7': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '10', '11'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '10'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '9', '10', '11', '12'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '9'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '6', '7', '8', '9'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '7'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['4', '5', '7', '8'],
        market: 'Corner',
      },
    },
  },
  '10': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '13', '14'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '13'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '12', '13', '14', '15'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '12'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '9', '10', '11', '12'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '10'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['7', '8', '10', '11'],
        market: 'Corner',
      },
    },
  },
  '13': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '16', '17'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '16'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '15', '16', '17', '18'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '15'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '12', '13', '14', '15'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '13'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['10', '11', '13', '14'],
        market: 'Corner',
      },
    },
  },
  '16': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '19', '20'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '19'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '18', '19', '20', '21'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '18'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '15', '16', '17', '18'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '16'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['13', '14', '16', '17'],
        market: 'Corner',
      },
    },
  },
  '19': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '22', '23'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '22'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '21', '22', '23', '24'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '21'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '18', '19', '20', '21'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '19'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['16', '17', '19', '20'],
        market: 'Corner',
      },
    },
  },
  '22': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '25', '26'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '25'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '24', '25', '26', '27'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '24'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '21', '22', '23', '24'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '22'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['19', '20', '22', '23'],
        market: 'Corner',
      },
    },
  },
  '25': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '28', '29'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '28'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '27', '28', '29', '30'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '27'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '24', '25', '26', '27'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '25'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['22', '23', '25', '26'],
        market: 'Corner',
      },
    },
  },
  '28': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '31', '32'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '31'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '30', '31', '32', '33'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '30'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '27', '28', '29', '30'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '28'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['25', '26', '28', '29'],
        market: 'Corner',
      },
    },
  },
  '31': {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32'],
        market: 'Split',
      },
      'SW-TR': {
        class: 'SW-TR',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '34', '35'],
        market: 'Corner',
      },
      'SW-R': {
        class: 'SW-R',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '34'],
        market: 'Split',
      },
      'SW-BR': {
        class: 'SW-BR',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '33', '34', '35', '36'],
        market: 'Six',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '33'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '30', '31', '32', '33'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '31'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['28', '29', '31', '32'],
        market: 'Corner',
      },
    },
  },
  '34': {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Win',
    buttons: {
      'SW-T': {
        class: 'SW-T',
        isSelected: false,
        isActive: false,
        numberRange: ['34', '35'],
        market: 'Split',
      },
      'SW-B': {
        class: 'SW-B',
        isSelected: false,
        isActive: false,
        numberRange: ['34', '35', '36'],
        market: '3Line',
      },
      'SW-BL': {
        class: 'SW-BL',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '33', '34', '35', '36'],
        market: 'Six',
      },
      'SW-L': {
        class: 'SW-L',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '34'],
        market: 'Split',
      },
      'SW-TL': {
        class: 'SW-TL',
        isSelected: false,
        isActive: false,
        numberRange: ['31', '32', '34', '35'],
        market: 'Corner',
      },
    },
  },
  Col3: {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'Column',
    numberRange: ['3', '6', '9', '12', '15', '18', '21', '24', '27', '30', '33', '36'],
  },
  Col2: {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'Column',
    numberRange: ['2', '5', '8', '11', '14', '17', '20', '23', '26', '29', '32', '35'],
  },
  Col1: {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'Column',
    numberRange: ['1', '4', '7', '10', '13', '16', '19', '22', '25', '28', '31', '34'],
  },
  '1st 12': {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'Dozens',
    numberRange: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
  },
  '2nd 12': {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'Dozens',
    numberRange: ['13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
  },
  '3rd 12': {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'Dozens',
    numberRange: ['25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
  },
  '1-18': {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'HighLow',
    numberRange: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
    ],
  },
  '19-36': {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'HighLow',
    numberRange: [
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
    ],
  },
  Odd: {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'OddEven',
    numberRange: [],
  },
  Even: {
    isSelected: false,
    isHovered: false,
    bgColor: 'transparent',
    market: 'OddEven',
    numberRange: [],
  },
  Red: {
    isSelected: false,
    isHovered: false,
    bgColor: '#f00',
    market: 'Color',
    numberRange: [],
  },
  Black: {
    isSelected: false,
    isHovered: false,
    bgColor: '#000',
    market: 'Color',
    numberRange: [],
  },
  '32/15/19/4/21/2': {
    isSelected: false,
    isHovered: false,
    bgColor: '#ff8f17',
    market: 'Sectors',
    numberRange: ['32', '15', '19', '4', '21', '2'],
  },
  '25/17/34/6/27/13': {
    isSelected: false,
    isHovered: false,
    bgColor: '#163fff',
    market: 'Sectors',
    numberRange: ['25', '17', '34', '6', '27', '13'],
  },
  '36/11/30/8/23/10': {
    isSelected: false,
    isHovered: false,
    bgColor: '#ff5aff',
    market: 'Sectors',
    numberRange: ['36', '11', '30', '8', '23', '10'],
  },
  '5/24/16/33/1/20': {
    isSelected: false,
    isHovered: false,
    bgColor: '#00cf9e',
    market: 'Sectors',
    numberRange: ['5', '24', '16', '33', '1', '20'],
  },
  '14/31/9/22/18/29': {
    isSelected: false,
    isHovered: false,
    bgColor: '#fff300',
    market: 'Sectors',
    numberRange: ['14', '31', '9', '22', '18', '29'],
  },
  '7/28/12/35/3/26': {
    isSelected: false,
    isHovered: false,
    bgColor: '#fff',
    market: 'Sectors',
    numberRange: ['7', '28', '12', '35', '3', '26'],
  },
  neighbors: {
    '0': {
      isSelected: false,
      isHovered: false,
      bgColor: '#008000',
      numberRange: ['3', '26', '0', '32', '15'],
      market: 'Neighbors',
    },
    '1': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['16', '33', '1', '20', '14'],
      market: 'Neighbors',
    },
    '2': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['4', '21', '2', '25', '17'],
      market: 'Neighbors',
    },
    '3': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['12', '35', '3', '26', '0'],
      market: 'Neighbors',
    },
    '4': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['15', '19', '4', '21', '2'],
      market: 'Neighbors',
    },
    '5': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['23', '10', '5', '24', '16'],
      market: 'Neighbors',
    },
    '6': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['17', '34', '6', '27', '13'],
      market: 'Neighbors',
    },
    '7': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['18', '29', '7', '28', '12'],
      market: 'Neighbors',
    },
    '8': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['11', '30', '8', '23', '10'],
      market: 'Neighbors',
    },
    '9': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['14', '31', '9', '22', '18'],
      market: 'Neighbors',
    },
    '10': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['8', '23', '10', '5', '24'],
      market: 'Neighbors',
    },
    '11': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['13', '36', '11', '30', '8'],
      market: 'Neighbors',
    },
    '12': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['7', '28', '12', '35', '3'],
      market: 'Neighbors',
    },
    '13': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['6', '27', '13', '36', '11'],
      market: 'Neighbors',
    },
    '14': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['1', '20', '14', '31', '9'],
      market: 'Neighbors',
    },
    '15': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['0', '32', '15', '19', '4'],
      market: 'Neighbors',
    },
    '16': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['5', '24', '16', '33', '1'],
      market: 'Neighbors',
    },
    '17': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['2', '25', '17', '34', '6'],
      market: 'Neighbors',
    },
    '18': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['9', '22', '18', '29', '7'],
      market: 'Neighbors',
    },
    '19': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['32', '15', '19', '4', '21'],
      market: 'Neighbors',
    },
    '20': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['33', '1', '20', '14', '31'],
      market: 'Neighbors',
    },
    '21': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['19', '4', '21', '2', '25'],
      market: 'Neighbors',
    },
    '22': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['31', '9', '22', '18', '29'],
      market: 'Neighbors',
    },
    '23': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['30', '8', '23', '10', '5'],
      market: 'Neighbors',
    },
    '24': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['10', '5', '24', '16', '33'],
      market: 'Neighbors',
    },
    '25': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['21', '2', '25', '17', '34'],
      market: 'Neighbors',
    },
    '26': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['35', '3', '26', '0', '32'],
      market: 'Neighbors',
    },
    '27': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['34', '6', '27', '13', '36'],
      market: 'Neighbors',
    },
    '28': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['29', '7', '28', '12', '35'],
      market: 'Neighbors',
    },
    '29': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['22', '18', '29', '7', '28'],
      market: 'Neighbors',
    },
    '30': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['36', '11', '30', '8', '23'],
      market: 'Neighbors',
    },
    '31': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['20', '14', '31', '9', '22'],
      market: 'Neighbors',
    },
    '32': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['26', '0', '32', '15', '19'],
      market: 'Neighbors',
    },
    '33': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['24', '16', '33', '1', '20'],
      market: 'Neighbors',
    },
    '34': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['25', '17', '34', '6', '27'],
      market: 'Neighbors',
    },
    '35': {
      isSelected: false,
      isHovered: false,
      bgColor: '#000',
      numberRange: ['28', '12', '35', '3', '26'],
      market: 'Neighbors',
    },
    '36': {
      isSelected: false,
      isHovered: false,
      bgColor: '#f00',
      numberRange: ['27', '13', '36', '11', '30'],
      market: 'Neighbors',
    },
  },
};
