import { PagesType } from './types';

export const protectedPages: PagesType = {
  home: {
    id: 'home',
    text: 'home',
    path: '/home',
  },
};

export const commonPages: PagesType = {};

export const publicPages: PagesType = {
  login: {
    id: 'login',
    text: 'Login',
    path: '/login',
  },
  forgotPassword: {
    id: 'forgotPassword',
    text: 'Forgot password',
    path: '/forgot-password',
  },
  resetPassword: {
    id: 'resetPassword',
    text: 'Reset password',
    path: '/reset-password',
  },
};

// Apply route guarding for inner pages too
export const noAccessReq = [...Object.values(commonPages).map((item) => item.path)];

export const noAsidePages = [...Object.values(publicPages).map((item) => item.path)];
