import { Navigate, Route, Routes } from 'react-router-dom';
import { protectedRoutes, publicRoutes } from 'routes/contentRoutes';
import { protectedPages } from 'routes/menu';
import ProtectedRoutes from './ProtectedRoutes';

const ContentRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        {protectedRoutes.map((page) => {
          return <Route key={page.path} path={page.path} element={page.element} />;
        })}
      </Route>
      {publicRoutes.map((page) => {
        return <Route key={page.path} path={page.path} element={page.element} />;
      })}
      <Route path="*" element={<Navigate to={protectedPages.home.path} replace />} />
    </Routes>
  );
};

export default ContentRoutes;
