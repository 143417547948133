import { createSlice, Slice } from '@reduxjs/toolkit';

type GameType = {
  id: number | null;
  gameNumber: number | null;
  name: string;
  createdAt: Date;
  cdm: number;
  rule: object;
};

const initialState: GameType = {
  id: null,
  gameNumber: null,
  name: '',
  createdAt: new Date(),
  cdm: 0,
  rule: {},
};

const gameSlice: Slice<GameType> = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGame: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
});

// eslint-disable-next-line operator-linebreak
export const { setGame } = gameSlice.actions;
export default gameSlice.reducer;
