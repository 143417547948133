import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'components/Toast';
import { logout, setAuth } from 'features/auth/userSlice';
import FetchError from 'utils/errors';

const noAuthEndpoints: string[] = ['login'];

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (
  args,
  api,
  extraOptions
) => {
  const state = api.getState();
  const baseUrl = state.user.apiUrl;
  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    credentials: 'include',
    prepareHeaders: (headers, { endpoint }) => {
      const { access } = state.user.tokens;
      if (access && !noAuthEndpoints.includes(endpoint)) {
        headers.set('Authorization', `Bearer ${access}`);
      } else {
        headers.delete('Authorization');
      }
      return headers;
    },
  });
  return rawBaseQuery(args, api, extraOptions);
};

const execRefreshToken = async (args: string | FetchArgs, api: BaseQueryApi) => {
  const { apiUrl, tokens } = api.getState().user;
  const { refresh } = tokens;

  if (refresh === null || refresh === undefined) {
    api.dispatch(logout({}));
    return false;
  }

  const refreshResultRaw = await fetch(`${apiUrl}/auth/refresh/`, {
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ refresh }),
  });

  if (refreshResultRaw.status !== 200) {
    api.dispatch(logout({}));
    return false;
  }

  const refreshResult = await refreshResultRaw.json();
  api.dispatch(
    setAuth({
      tokens: { access: refreshResult.access, refresh },
    })
  );
  return true;
};

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraOptions: any
) => {
  let result = await dynamicBaseQuery(args, api, extraOptions);
  const error = result?.error;
  if (error?.status === 401) {
    const isRefreshed = await execRefreshToken(args, api);
    if (isRefreshed) {
      // re submit request
      result = await dynamicBaseQuery(args, api, extraOptions);
      return result;
    }
  } else if (error) {
    // handle different errors here
    let errMessage = '';
    if (error.status === 429) {
      errMessage = 'Too many requests, please try again after sometime.';
      throw new FetchError(errMessage, error.status);
    } else if (typeof error.status === 'number' && error.status >= 500) {
      errMessage = 'Something went wrong from our side, please contact admin for more.';
      toast({ message: errMessage, severity: 'error' });
      throw new FetchError(errMessage, error.status);
    } else if (error.status === 'FETCH_ERROR' || error.status === 'TIMEOUT_ERROR') {
      errMessage =
        'There seems to be a network problem, Please check your wifi/internet connection and try again.';
      toast({ message: errMessage, severity: 'error' });
      throw new FetchError(errMessage, error.status);
    } else if (error.status === 'PARSING_ERROR' || error.status === 'CUSTOM_ERROR') {
      errMessage = 'Something went wrong while parsing the response.';
      toast({ message: errMessage, severity: 'error' });
      throw new FetchError(errMessage, error.status);
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['shop'],
  endpoints: () => ({}),
});
