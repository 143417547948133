import { createSlice, Slice } from '@reduxjs/toolkit';

type PrintType = {
  cancel: boolean;
  redeem: boolean;
};

const initialState: PrintType = {
  cancel: true,
  redeem: true,
};

const printSlice: Slice<PrintType> = createSlice({
  name: 'print',
  initialState,
  reducers: {
    setPrintReciept: (state, { payload }) => {
      state[payload.location] = payload.value;
    },
  },
});

export const { setPrintReciept } = printSlice.actions;
export default printSlice.reducer;
