import ErrorBoundary from 'components/errors/ErrorBoundary';
import Content from '../Content/Content';

const Wrapper = () => {
  return (
    <ErrorBoundary>
      <Content />
    </ErrorBoundary>
  );
};

export default Wrapper;
