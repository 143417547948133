import { createSlice, Slice } from '@reduxjs/toolkit';

export type ShopType = {
  id: number | null;
  name: string;
  minStake: number;
  maxStake: number;
  currency: string;
};

const initialState: ShopType = {
  id: null,
  name: '',
  minStake: 1,
  maxStake: 100000,
  currency: 'Br',
};

const shopSlice: Slice<ShopType> = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setShop: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
});

// eslint-disable-next-line operator-linebreak
export const { setShop } = shopSlice.actions;
export default shopSlice.reducer;
