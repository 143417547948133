class FetchError extends Error {
  status: number | string;

  constructor(message: string, status: number | string) {
    super(message);
    this.status = status;
  }
}

export default FetchError;
