import { configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import appReducer from 'features/app/appSlice';
import userReducer from 'features/auth/userSlice';
import betSlipReducer from 'features/betSlip/betSlipSlice';
import shopReducer from 'features/auth/shopSlice';
import kenoReducer from 'features/keno/kenoSlice';
import spinReducer from 'features/spin/spinSlice';
import gameReducer from 'features/games/gameSlice';
import cashierReducer from 'features/modals/cashierSlice';
import printReducer from 'features/app/printSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { DEBUG } from 'constants/settings';
import { apiSlice } from './api/apiSlice';

const persistUserConfig = {
  key: 'user',
  storage,
};

const persistShopConfig = {
  key: 'shop',
  storage,
};

const persistPrintConfig = {
  key: 'print',
  storage,
};

const persistedUserReducer = persistReducer(persistUserConfig, userReducer);
const persistedShopReducer = persistReducer(persistShopConfig, shopReducer);
const persistedPrintReducer = persistReducer(persistPrintConfig, printReducer);

export const store: ToolkitStore = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    user: persistedUserReducer,
    shop: persistedShopReducer,
    print: persistedPrintReducer,
    app: appReducer,
    keno: kenoReducer,
    spin: spinReducer,
    betSlip: betSlipReducer,
    game: gameReducer,
    cashier: cashierReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware);
  },
  devTools: DEBUG,
});

export const persistor = persistStore(store);
