import { createSlice, Slice } from '@reduxjs/toolkit';

const nums = Array.from({ length: 80 }, (_, i) => i + 1);
const numbers: { [key: number]: boolean } = nums.reduce((acc, cur) => {
  acc[cur] = false;
  return acc;
}, {});

type KenoState = {
  selections: number[];
  numbers: typeof numbers;
  cleared: boolean;
  refreshed: boolean;
  repeat: number;
  quickPicks: number;
};

const initialState: KenoState = {
  selections: [],
  numbers,
  cleared: true,
  refreshed: true,
  repeat: 1,
  quickPicks: 0,
};

const kenoSlice: Slice<KenoState> = createSlice({
  name: 'keno',
  initialState,
  reducers: {
    toggleNum: (state, { payload }) => {
      state.refreshed = true;
      if (!state.numbers[payload.num]) {
        if (state.selections.length >= 10) return;
        state.numbers[payload.num] = true;
        state.selections.push(payload.num);
        return;
      }
      state.numbers[payload.num] = false;
      const index = state.selections.indexOf(payload.num);
      if (index > -1) {
        state.selections.splice(index, 1);
      }
    },
    setRefreshed: (state, { payload }) => {
      state.refreshed = payload.status;
    },
    setCleared: (state, { payload }) => {
      state.cleared = payload.status;
      // if cleared is true
      if (payload.status) {
        state.selections.forEach((num) => {
          state.numbers[num] = false;
        });
        state.selections = [];
        state.repeat = 1;
        state.quickPicks = 0;
        state.refreshed = true;
      }
    },
    setRepeat: (state, { payload }) => {
      state.repeat = payload.repeat;
    },
    setQuickPicks: (state, { payload }) => {
      state.quickPicks = payload.quickPicks;
    },
    quickPick: (state) => {
      const tempNums: number[] = [];
      for (let i = 0; i < state.quickPicks; i += 1) {
        let num = Math.floor(Math.random() * 79) + 1;
        do {
          num = Math.floor(Math.random() * 79) + 1;
        } while (tempNums.includes(num));
        tempNums.push(num);
      }
      // reset picked values
      state.selections.forEach((num) => {
        state.numbers[num] = false;
      });
      state.selections = [];
      state.refreshed = true;
      // add tempNums
      tempNums.forEach((num) => {
        state.numbers[num] = true;
        state.selections.push(num);
      });
    },
  },
});

// eslint-disable-next-line operator-linebreak
export const { toggleNum, setCleared, setRefreshed, setRepeat, quickPick, setQuickPicks } =
  kenoSlice.actions;
export default kenoSlice.reducer;
