import { Box, CircularProgress } from '@mui/material';

const PageLoadingIndicator = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress disableShrink />
    </Box>
  );
};

export default PageLoadingIndicator;
