import { createSlice, Slice } from '@reduxjs/toolkit';

type CashierType = {
  gridButtonsFocus: number;
};

const initialState: CashierType = {
  gridButtonsFocus: 0,
};

const cashierSlice: Slice<CashierType> = createSlice({
  name: 'cashier',
  initialState,
  reducers: {
    focusOnGrid: (state) => {
      state.gridButtonsFocus += 1;
    },
  },
});

export const { focusOnGrid } = cashierSlice.actions;
export default cashierSlice.reducer;
