import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Fade, IconButton, Snackbar } from '@mui/material';
import React, { FC } from 'react';
import hotToast, { Toaster as HotToaster } from 'react-hot-toast';

export const Toaster = HotToaster;
export type SeverityType = 'success' | 'info' | 'warning' | 'error';

interface Props {
  message: string;
  severity?: SeverityType;
}

interface ToastProps extends Props {
  visible: boolean;
  onClose: (e: React.SyntheticEvent) => void;
}

const Toast: FC<ToastProps> = (props) => {
  const { visible, message, onClose, severity, ...rest } = props;

  const duration = severity === 'success' ? 3000 : 5000;

  return (
    <Snackbar open={visible} autoHideDuration={duration}>
      <Alert onClose={onClose} sx={{ width: '100%' }} severity={severity} {...rest}>
        {message}
      </Alert>
    </Snackbar>
  );
};

Toast.defaultProps = { severity: 'success' };

export const toast = (opts: Props) => {
  const { message, severity } = opts;

  return hotToast.custom(({ visible, id }) => (
    <Toast
      visible={visible}
      message={message}
      severity={severity}
      onClose={() => {
        hotToast.dismiss(id);
      }}
    />
  ));
};

export const CustomToast: FC<ToastProps> = ({ message, severity, visible, onClose }) => {
  const clr = { success: 'success.main', info: 'info', warning: 'warning', error: '#db4240' };

  return (
    <Fade in={visible}>
      <Box
        sx={{
          width: '100%',
          color: '#fff',
          fontSize: 14,
          backgroundColor: clr[severity],
          backgroundImage: 'linear-gradient(rgba(255,255,255,.25) 0,rgba(255,255,255,0) 100%)',
          p: 1,
          borderRadius: 2,
          display: visible ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'space-between',
          my: 1,
        }}
      >
        {message}
        <IconButton size="small" onClick={onClose} sx={{ ml: 1, height: 20, width: 20 }}>
          <CloseIcon sx={{ color: '#fff', width: 16 }} />
        </IconButton>
      </Box>
    </Fade>
  );
};

CustomToast.defaultProps = { severity: 'success' };
